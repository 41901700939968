import {
    Abstract
} from './Abstract';

export class MeasureUnit extends Abstract {
    constructor(session) {
        super('comercial/MeasureUnit', session);
        this.name = null;
        this.key_name = null;
        this.sat_code = null;
    }
}
